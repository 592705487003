import { Linking } from 'react-native'
import { find } from 'lodash'
import moment from 'moment'
import Emoji from 'components/Emoji'

/**
 * @param {string} url
 */
export const openURL = (url, ext) => Linking.openURL(url)

// Object utils
export const map = (obj, fn) => Object.keys(obj).map(prop => fn(obj[prop]))
export const filter = (obj, fn) =>
  Object.keys(obj)
    .filter(prop => fn(obj[prop]))
    .map(prop => obj[prop])
export const reduce = (obj, fn, initial) =>
  Object.keys(obj).reduce((curr, prop) => fn(curr, obj[prop]), initial)
export const empty = obj => Object.keys(obj).length === 0

function capitalizeFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function camelToUnderStr(str) {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase()
}

export function underToCamelStr(str) {
  return str
    .split(/_/)
    .map((val, i) => (i === 0 ? val : capitalizeFirst(val)))
    .join('')
}

export function camelToUnderObj(obj) {
  const res = {}
  for (let key of Object.keys(obj)) {
    res[camelToUnderStr(key)] = obj[key]
  }
  return res
}

export function underToCamelObj(obj) {
  const res = {}
  for (let key of Object.keys(obj)) {
    res[underToCamelStr(key)] = obj[key]
  }
  return res
}

export function getGradesRange(grades) {
  const reMap = [
    { label: 'PK', re: 'Pre-K|PK', order: 1 },
    { label: 'K', re: 'K', order: 2 },
    { label: '1', re: /^(1|1st|first|one)$/, order: 3 },
    { label: '2', re: /^(2|2nd|second|two)$/, order: 4 },
    { label: '3', re: '3|3rd|third|three', order: 5 },
    { label: '4', re: '4|4th|fourth|four', order: 6 },
    { label: '5', re: '5|5th|fifth|five', order: 7 },
    { label: '6', re: '6|6th|sixth|six', order: 8 },
    { label: '7', re: '7|7th|seventh|seven', order: 9 },
    { label: '8', re: '8|8th|eighth|eight', order: 10 },
    { label: '9', re: '9|9th|ninth|nine', order: 11 },
    { label: '10', re: /^(10|10th|tenth|ten)$/, order: 12 },
    { label: '11', re: /^(11|11th|eleventh|eleven)$/, order: 13 },
    { label: '12', re: /^(12|12th|twelfth|twelve)$/, order: 14 },
  ]
  if (typeof grades === 'string') {
    return grades
  }
  return grades
    .map(grade => find(reMap, ({ re }) => new RegExp(re).test(grade)))
    .sort((a, b) => a.order - b.order)
    .reduce((prev, curr, i, arr) => {
      if (i === 0) {
        return curr.label
      } else if (curr.order - arr[i - 1].order === 1) {
        const re = new RegExp(`—${arr[i - 1].label}$`)
        return prev.replace(re, '') + `—${curr.label}`
      } else {
        return `${prev}, ${curr.label}`
      }
    }, '')
}

export const GRADES = [
  {
    label: 'PK',
    value: 'PK',
  },
  {
    label: 'K',
    value: 'K',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
]

export const SUBJECTS = [
  {
    label: 'Math',
    value: 'Math',
  },
  {
    label: 'Science',
    value: 'Science',
  },
  {
    label: 'Social Studies',
    value: 'Social Studies',
  },
  {
    label: 'Computer Science',
    value: 'Computer Science',
  },
  {
    label: 'ELA',
    value: 'ELA',
  },
  {
    label: 'Art',
    value: 'Art',
  },
  {
    label: 'Engineering/Tech',
    value: 'Engineering/Tech',
  },
  {
    label: 'SEL/Digital Citizenship',
    value: 'SEL/Digital Citizenship',
  },
]

export const AUTHORS = [
  {
    label: 'Ozobot',
    value: 'Ozobot',
  },
  {
    label: 'Community',
    value: 'Community',
  },
]

export const BOTS = [
  {
    label: 'Ari',
    value: 'Ari',
    adminOnly: true,
  },
  {
    label: 'Evo',
    value: 'Evo',
  },
  {
    label: 'Bit',
    value: 'Bit',
  },
  {
    label: 'Bit+',
    value: 'Bit+',
  },
  {
    label: 'ORA',
    value: 'ORA',
  },
  {
    label: 'MetaBot',
    value: 'MetaBot',
  },
]

export const CODING_STYLES = [
  {
    label: 'Color Codes',
    value: 'Color Codes',
  },
  {
    label: 'Ozobot Blockly',
    value: 'OzoBlockly',
  },
  {
    label: 'MetaBot',
    value: 'MetaBot',
  },
  {
    label: 'Ozobot Editor',
    value: 'Ozobot Editor',
    adminOnly: true,
  },
  {
    label: 'Ari App',
    value: 'Ari App',
    adminOnly: true,
  }, 
]

export const CODING_STYLES_FOR_CREATOR = [
  ...CODING_STYLES,
  {
    label: 'Python',
    value: 'Python',
    adminOnly: true,
  },  
]

export const PRODUCTS = [
  {
    label: 'Challenge Mats',
    value: 'Challenge Mats',
  },
  {
    label: 'STEAM Kits',
    value: 'STEAM Kits',
  },
  {
    label: 'Color Code Magnets',
    value: 'Color Code Magnets',
  },
  {
    label: 'Ozobot Crawler',
    value: 'Ozobot Crawler',
  },
  {
    label: 'Lettuce Grow',
    value: 'Lettuce Grow',
    adminOnly: true,
  },
]

export const ARI_LESSON_APPS = [
  {
    label: 'Color Codes',
    value: 'com.ozobot.basebehavior',
    icon: require('images/lesson-app-icons/color-codes.png'),
  },
  {
    label: 'The Night Sky',
    value: 'com.ozobot.lessons.nightsky',
    icon: require('images/lesson-app-icons/night-sky.png'),
  },
  {
    label: 'Life Cycles',
    value: 'com.ozobot.lessons.lifecycle',
    icon: require('images/lesson-app-icons/life-cycle.png'),
  },
  {
    label: 'Distance',
    value: 'com.ozobot.lessons.distance',
    icon: require('images/lesson-app-icons/distance.png'),
  },
  {
    label: 'Meet Ari',
    value: 'com.ozobot.lessons.demo',
    icon: require('images/lesson-app-icons/demo.png'),
  },
]

const lessonAppValueToLessonApp = {}
for(const e of ARI_LESSON_APPS) { lessonAppValueToLessonApp[e.value] = e }

export const lessonAppFromValue = (lessonAppValue) => lessonAppValueToLessonApp[lessonAppValue]

export const DURATION = [
  {
    label: '< 15 min',
    value: '< 15 min',
  },
  {
    label: '15-45 min',
    value: '15-45 min',
  },
  {
    label: '45 min+',
    value: '45 min+',
  },
]

export const STANDARD_CATEGORIES = [
  {
    label: 'CCSS.MATH',
    value: 'CCSS.MATH',
  },
  {
    label: 'CCSS.ELA',
    value: 'CCSS.ELA',
  },
  {
    label: 'NGSS',
    value: 'NGSS',
  },
  {
    label: 'CSTA',
    value: 'CSTA',
  },
  {
    label: 'ISTE',
    value: 'ISTE',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

export const PREREADER = [
  {
    label: 'Yes',
    value: 'True',
  },
]

export const CERTIFIED = [
  {
    label: 'Yes',
    value: 'True',
  },
]

export const HAS_VIDEO = [
  {
    label: 'Yes',
    value: 'True',
  },
]

export const CATEGORIES = [
  {
    label: 'Ari Lessons',
    value: 'Ari Lessons',
  },
  {
    label: 'Featured Lessons',
    value: 'Featured Lessons',
  },
  {
    label: 'Introduction to Color Codes',
    value: 'Introduction to Color Codes',
  },
  {
    label: 'Introduction to Blockly',
    value: 'Introduction to Blockly',
  },
  {
    label: 'STEAM Kits',
    value: 'STEAM Kits',
  },
  {
    label: 'Introduction to Python',
    value: 'Introduction to Python',
  },
]

export const OZOBOT_AUTHOR_ID = 'aaAs2wj8lnQlaLkGqaiVGJhAm0'

export function getLocalDueAt({ dueDate, dueTime }) {
  const { day, year, month } = dueDate
  const { hours = 0, minutes = 0 } = dueTime

  return moment.utc([year, month - 1, day, hours, minutes]).local()
}

export const timeStr = time => {
  if (time > 0) {
    const secondsDiv = 1000
    const minutesDiv = 60 * secondsDiv
    const hoursDiv = 60 * minutesDiv
    const hours = Math.floor(time / hoursDiv)
    const minutes = Math.floor((time - hours * hoursDiv) / minutesDiv)
    const seconds = Math.floor(
      (time - hours * hoursDiv - minutes * minutesDiv) / secondsDiv
    )
    const hoursStr = hours > 0 ? `${hours}:` : ''
    const minutesStr =
      minutes > 0
        ? `${minutes < 10 && hoursStr ? '0' : ''}${minutes}:`
        : hoursStr
        ? `00:`
        : `0:`
    const secondsStr =
      seconds > 0 ? `${seconds < 10 ? '0' : ''}${seconds}` : `00`
    return `${hoursStr}${minutesStr}${secondsStr}`
  }
}

export const portalLessons = {
  create: 'ln6lf7nBnfQyqjy1PkNJIeigJ3',
  'evo-breakout-level-3-points': 'ln7wijtQpBSnen5Wph59tquArk',
  'learn-red-path-stack': 'ln5VmQw7MHRLqnQ7erOCSzhgaa',
  'evo-breakout-level-5-game-design': 'lnD5AkESWNTzuQRV97z0WZjAvQ',
  'summer-games-contest': 'lneDlHaRmrTbaCLZ8hAtpXbQXs',
  'math-identity': 'lnq80j37jyQLmWeoyns2GEPgKK',
  'evo-breakout-level-2-timer': 'lncDQxP5d0SGOxuxie4a2ndgM1',
  'ozobot-odyssey': 'ln7l7bETYZRRiZp7HhhXFAnQ71',
  'clean-energy-cruise': 'ln8KdiNsPtQ1SrByDcKskCugJv',
  'game-level': 'ln8QVgH3dWTFyXBQsk7w6ATwxj',
  'perimeter-ozobot-race': 'ln8snMqJhsT9mAFKjG5l0IJQ6T',
  'uniform-circular-motion': 'ln016OoNAQQgyjxLSgZoDqHg0G',
  'pythagorean-theorem': 'ln0sUo4fxVRyanKGy11mEVjQRr',
  'cold-lady': 'lnk7UKLZHJSdkBPcO39kU9VgLq',
  'ozo-claus': 'ln1UnMPXgYSF2WOL6OnidqgA0o',
  'evo-colorquest-level-5-game-design': 'ln2cWKDHTvTeyR86fKKIcUjQkN',
  'winter-parade': 'lnf8S0eH0bTT68rOcLKxIboA6e',
  'ozobot-sees': 'ln2IvLRZEGTWGJ2ngA7HUUtgGD',
  'random-variables-and-probability': 'ln3fEzT6MzSVioP2ow9kF5sgr2',
  'evo-self-driving-lesson': 'lnlaZyVN0VTrSHTekX3saKPg8o',
  'easter-egg-activity': 'lnNDvIUcAxTfqFTDsRQyuwigh7',
  'honoring-ancestors': 'ln4SBkRibwSsLky7poRAhwyQxC',
  'ozo-plow': 'lnS4W3DWKVRrGjdLmMLwcR5ws9',
  'speaking-prime-numbers': 'ln58P73PDqSH67D9RMfZ15BwFo',
  recyclebot: 'ln5HCJNexLS0qiyPeJU4PxbgTP',
  'elementary-cs-gdd': 'ln6KPSv5tlSAuBh4IYGcPQAQqA',
  'great-ozorace': 'ln70ShLNFMSVqa1J0LKJsIVQVM',
  'immigration-with-ozobot': 'ln78fLSAIxSJiqm3RRpVuKpgHo',
  'ozobot-workshop-2': 'ln7Blp1WxJRaCKZL8VypoaEg4t',
  'magellans-journey': 'ln99Pbkx8pTGOZX61vUER3QAUD',
  'randomness-and-runs': 'ln9SrlnC87QLym8LoPWt2nNQie',
  'ebtl3-sensors': 'lnGj9qZ9NvQ8iIInHa8dZDCgi9',
  'ozobot-finds-love': 'ln9U0efwD7QyQkm9B2gWLYngbu',
  'deal-poker-hands': 'lngottGpRPRHqGHUB3qztzog7Z',
  'random-walk': 'lnaBM1b1HFRIedc4anYSz05wcV',
  'gods-of-love-and-war': 'lnAxDVWmdOT1SASefnPqep0wLa',
  'slope-practice': 'lnb35T4D90T8ynoOQcCKeJFwbG',
  'evo-hockey-level-4-scoring': 'lnbFcD5BcWQhaUdsk4oniO1AMH',
  'probability-and-statistics': 'lnBFxnyz8BSgGxvPaxkk7EAACv',
  'experiment-viscosity': 'lnltqhlsyqRuWIGa4H4cpwOAa0',
  'modeling-animal-habits': 'lnbqFXcCmYQnSitM2dmN6a8AA1',
  'second-timer': 'lnC0t0oAgtRuWVt2pVpv4vLgf2',
  'evo-the-troll': 'lnR34rA7VrQBGfX60lQ1PmggYg',
  'ozobot-growing-patterns': 'ln8ZYEGylJRg2y9NgxbLCUZg1z',
  'geometry-task-cards': 'lnioodJ3D5RCi4eZ6QqSi3FAhp',
  'multiplication-algorithm': 'lnD1MBjyKRSPyKcHXUBdWGVQ6Y',
  'dance-party': 'lnA5BoVd67RRmD15MMTzGl0A41',
  'pokemon-hunt': 'lndaCUdwuHSgq4hCzyyr3dUQgi',
  'decorating-easter-eggs': 'ln9yKQVt4HSK6oCLsPt2GVewNU',
  'evo-force-field': 'lndbdlIEssTfCqawED57Hx9QkA',
  'fraction-challenge': 'lnDck1jQ8TSe2ppkG4AHbvgAW5',
  'cartesian-coordinate-practice': 'lnDiDunfVoRoKkj9votqO0FQAb',
  'space-exploration-game': 'lnkfZxRx38Qe2UEsYTjlq1kw59',
  'linear-angular-kinematics': 'lnDIiY24p4R4SXdRI5ro7XjgE5',
  'automatic-braking': 'lnDUckUtDLT2KKjXgDiBJhMwhP',
  'evo-colorquest-level-4-game-mechanics': 'lnE4FHc72hQTSwM09xJnoIyA4N',
  'drunkards-random-walk': 'lne7hLg7veQ2agMpmijJZR5QtK',
  'evo-hockey-level-3-maneuvering': 'lnhSf8hf9OQnCkGwRzZ5rEbgI7',
  'fairytale-lesson-2': 'lngPGUKoaOQu6yDslqG5M94QAp',
  'remembering-bridge-colors-arrays': 'lnEDSGmFCdTxuLSCUan60t7A62',
  'multiplication-table-practice': 'lnef7FuAIZRGCdyK14ayOFXAsu',
  'elementary-cs-lesson-4': 'lnFDopOgIlR5Siy82cG2sNMAr4',
  'maze-game': 'lngUQBRz94QPaqIxIVer63aQ6z',
  'house-lighting': 'lndlho8uBAS0GK5m8hBfK3Ag3u',
  'projectile-motion': 'lnfkmFPQwbQxWVudmItXpHLAUC',
  'convex-concave-lenses': 'lnFOMuNVFhRuqcDqdwEN0BqAD3',
  'hoc-ozobot-tutorial': 'lndtVziFjLR0GFEDLPmCWdWAaO',
  'ozobot-measurement': 'lnK7h5gBh9TDWFyQ1b1Kp0FwHv',
  'ozobot-obstacle-course': 'lng81N9EI3QPC1jUVhmQMU4wt3',
  'evo-math-operations': 'lnHxLH1ckiRp6Frif3yZW1wQPg',
  'dance-off': 'lnEBDUiDbNRbmJvFfUJpGfOQyI',
  'hungry-hungry-ozobot': 'lnghnykSMhS6Cz7W0v8eoFuA6f',
  'simple-harmonic-motion': 'lnGhRvHENpTTqffZ4YsmpWywZR',
  'ozobot-workshop-3': 'lnH9d3btVASzCEh5KTUiXYFADf',
  'evo-deconstruction-readme': 'lnHDolib05RFSM8sOOexjpLgHR',
  'evo-colorquest-level-1-movement': 'lnMNeC4xrlT7eaHVX5ktv3uAi7',
  'simulation-robotic-warehouse': 'lnho9vCfSQQjGeBvfSc1b2dAN6',
  'planetary-alignment': 'lnhOZnYt5RSg62D5u5WbhoiwrP',
  'how-to-program-robots': 'lnhUZL57hdQ0e4i7ehyDUcJABu',
  'evo-hockey-level-1-movement': 'lnhWqIcBmsSXWN6Xf6lGsieQRr',
  'business-process-modeling': 'lnI7jt8CVlQia1nNPclFeJvQM3',
  'geometric-figures-area': 'lnije61rlnRre9XMVbEdLTxQJu',
  'ozobot-trick-or-treat': 'lniOKY3KPiQPeZTvj8uMJxGgnH',
  'evo-breakout-level-4-multiplayer': 'lnqxnPpWuAQV6YIVHEbDSJFQmi',
  'mission-to-mars': 'lnIvJA3OJGQKyOjtx8qScrzAgs',
  'easter-egg-hunt': 'lnj4JA1VTGRcKrjGhk7fWgwgtx',
  'binary-blaster': 'lnj6VUkByrTECN8taiq1fgWwVU',
  'value-of-pi': 'lnJdwjHSydQ8RfSee7CH45IQ2R',
  'write-your-name': 'lnJjKEW7W8SWKNc6QWNHyZhwMq',
  'ebtl2-timer': 'lnJrJuivAtQVSyfLUZilFqlQ97',
  'random-story-generator': 'lnkxG2CTwTThebO9mIRdx37wUH',
  'life-on-other-planets': 'lnLJacedGUR4GL2Z3Kd9uWvQU4',
  'eclipses-celestial-mechanics': 'lnLoJZgeozTk6B66dMPdpMogv7',
  'ozobot-winter-olympics-2018': 'lnMBbH5QJpQlkCXP94vyKQQQg5',
  'cartesian-coordinates-arrays': 'lnmKNHbmNrRkabSEDjl8XFNwhB',
  'programming-with-colors': 'lnMNK4k8DgTIyBA83N4PgwiwcE',
  'light-trail-lite': 'lnmZsbWGkSQfmGkuKPnrjUGA9E',
  'buzz-buzz-game': 'lnN2dyzt0GTGqesWR4wDqBIwgi',
  'presidents-parade': 'lnnA6dsTV4RwaalnbmSBbeiQif',
  'position-vs-time': 'lnNjJkd3FdTSyHok9o1DwF2wtX',
  'ozobot-workshop-1': 'lnnQARrhm9QfyC8MCx6a9LVw6n',
  'dorothy-vaughan-fortran': 'lnnQXelC7UQLWlbpajwDeZPQpd',
  'pot-of-gold': 'lnNx1YSvepTZegDFsAmeN4UQFh',
  'repeat-path-array': 'lnNXbCTGxsRNO9nR5F7HFKIA6u',
  'ozoart-light-trails': 'lnnz0BeBNdTDujiayo4Ph5kwfD',
  'on-off-controllers': 'lnoBbxFa6AQfWpCXMAp6nYWAwK',
  'ozobots-meet-pokemon': 'lnoEKc1ziARGWIYo3XRHTggAZe',
  'nonstandard-measurement': 'lnrYIs9BDbToeJA7SzRfUEOQHH',
  'balmer-series': 'lnogNJEgxlTKu89baKbZGL0wrs',
  'elementary-cs-guide': 'lnoJ7Ju9RhTPqpIJWHeMAY0gPV',
  'ozogroove-transformations': 'lnONtLNTdsSD2YoIPQxsONKQUA',
  'pascals-triangle': 'lnoRvJFBCYT3C0rZDpWBncLQbJ',
  reinbot: 'lnOyruDYb7TQy8ymtgLZVgiAoM',
  'cube-challenges': 'lnPeXXV6jER46p3UukdIFkXQ4W',
  'random-spooky-story': 'lnq2ZnU0kUT3SVtrNwwsGCvAFr',
  'radiation-half-life': 'lnq3V0WVfKRamo1gotpffxqAH7',
  'evo-breakout-level-1-movement': 'lnS4JDlMWhR8OBP3WSBMqr0wlJ',
  'ozo-dash-100': 'lnql12yNPIQAO5BGeb0Q4Sgw63',
  'fibonacci-traveler': 'lnrFii8O6RT6WANi0ueftBXgOt',
  'automatic-breaking': 'lnRKsuIKaqQiupGoaNUf9cBwqC',
  'fairytale-lesson-1': 'lnRQMA8NAkTr2bfnAbC57HvAG4',
  'elementary-cs-lesson-3': 'lnrWplL1GaSoOs8XwG5S3skAZq',
  'array-primer': 'lnShqGsXvbS7uL3VBHdBK5OQJT',
  'morse-code-generator': 'lnSHwQfYdNSi2gbakohlw4vwxL',
  'evo-colorquest-level-3-points': 'lnSmoA1RYCSXCXGZhQXhKRMg5B',
  'evo-hockey-level-2-puck-handling': 'lnSnvChyOoTSaL3caWSH4LDw6a',
  'evo-vacuum-simulation': 'lnTGkRJWoASRyNoUdUQAIysQPj',
  'elementary-cs-lesson-2': 'lntiVPlG6PRHCwaTpLqrAmAAff',
  'golden-ratio': 'lntK7ebUleSiuOgRNCfwBKoQi9',
  'bit-growing-patterns': 'lnTKdKbLEXRhquJmkIUZHrtwM1',
  'future-burger': 'lnTm3gatkBRf6Vv5FT37R1QgZ2',
  'ebtl1-count-to-ten': 'lnTpC5kDVNTvK6jeAxdB169gav',
  'chronological-thinking': 'lnTPIRlzxxQmoRvcJAsWZxkgw3',
  'boyles-law': 'lntzaqYBYfQ2yeFhtx5LESMAnw',
  'ozobot-quadratics-project': 'lnUbDA7zdySX6O0n6SWtSjYgYt',
  'triangular-square-numbers': 'lnucBoW4nRQCezj8vfk0gTTQFv',
  'musical-tour': 'lnUCz9ytJTS82Wja8E7Y5mbw9R',
  'evo-hockey-level-5-game-design': 'lnuhmY5NIwROWlQ8KzVUySZQFJ',
  'decimal-binary-converter': 'lnUsAnG3ogQw62xoIPSrZtqw97',
  'geometry-task-cards-2': 'lnuwa0AqcuRrepB0timKRR3wve',
  'randomness-with-die': 'lnv0XbfQgHTFavCIKfR0QaXAJN',
  'reflection-from-mirrors': 'lnVcoe2zDqTvSKV7R89RVFQgcA',
  'evo-colorquest-level-2-winning': 'lnvJiE6g2jT9yfCtZYjPqF6Qng',
  'evo-keyboard-notes': 'lnVl620IZFSr3fEf0Zh5eL0wiH',
  'evo-bright': 'lnvZ8nAQTiT0yMPdymILgZow86',
  'bowling-with-ozobot': 'lnW2SjHK4qTla5Ladn0TtQMgnb',
  'coin-change': 'lnxDEWmi4HThy39cQrn1RCSguG',
  'program-simulator': 'lnxHZqYySyTYiQCE1ybO69SwsE',
  'ozobot-theatre': 'lnyjBOTRekQRSm5zmMqZfoMwMI',
  'law-of-light': 'lnyjiXeu2wSWqJDf8ImCZ1eAuq',
  'ozobot-transformations': 'lnYLkYjNHMR7aX2Gh5Bbmmxwns',
  'elementary-cs-lesson-1': 'lnyLvv6mbARcOps0poGUY9fQGH',
  'refraction-of-light': 'lnyQZD2TW3RraWWRmn2wud5g7L',
  'mission-to-neptune': 'lnyVsRQ1yZRoSO2oQMixO7OwoV',
  'keplers-law': 'lnYXDHIl0OQgWM9acLlQVTlQJv',
  'path-reversal-stack': 'lnyyqdolcCQjehcevFVr2VpQhQ',
  'construction-challenge': 'lnZEcs0IUVQmGq6eB6BzTVbQeG',
  'winter-scavenger-hunt': 'lnZFzzeZQMTmO4LjFM6F1uQwqb',
  'teaching-ozoblockly': 'lnzvpvfF9HS5q9wWRutzJvHgaQ',
  'data-input-proximity': 'lnqwKDz0A2QySiNTGAwL3t5QGF',
}

export function isIOS () {
  return (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
}

export function isAppLoadedInIframe() {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

export function makePDFViewURL(pdfURL) {
  return '/pdfjs/web/viewer.html?file=' + encodeURIComponent(pdfURL)
}
